import React from 'react';
import InternalOrExternalLink from './InternalOrExternalLink';

const Cta = ({ children, to, ...props }) => {
	return (
		<>
			{to ? (
				<InternalOrExternalLink
					// className='cta-btn'
					className="button"
					to={to} {...props}>
					{children}
					{/* <div className='cta-btn__content'>{children}</div>
					<div className='cta-btn__clip'>
						<div className='cta-btn__leftTop cta-btn__corner'></div>
						<div className='cta-btn__rightBottom cta-btn__corner'></div>
						<div className='cta-btn__rightTop cta-btn__corner'></div>
						<div className='cta-btn__leftBottom cta-btn__corner'></div>
					</div>
					<span className='cta-btn__rightArrow cta-btn__arrow'></span>
					<span className='cta-btn__leftArrow cta-btn__arrow'></span> */}
				</InternalOrExternalLink>
			) : (
				<>
					<button className='button' {...props}>
						{children}
					</button>
				</>
				// <div className='cta-btn'>
				// 	<div className='cta-btn__content'>{children}</div>
				// 	<div className='cta-btn__clip'>
				// 		<div className='cta-btn__leftTop cta-btn__corner'></div>
				// 		<div className='cta-btn__rightBottom cta-btn__corner'></div>
				// 		<div className='cta-btn__rightTop cta-btn__corner'></div>
				// 		<div className='cta-btn__leftBottom cta-btn__corner'></div>
				// 	</div>
				// 	<span className='cta-btn__rightArrow cta-btn__arrow'></span>
				// 	<span className='cta-btn__leftArrow cta-btn__arrow'></span>
				// </div>
			)}
		</>
	);
};

export default Cta;

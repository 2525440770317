import React from "react";

// External components
import Helmet from "react-helmet";

const SEO = ({ title, description, tags, image }) => {
    return (
        <Helmet>
            <title>{`Sørsport${title ? " - " + title : ""}`}</title>
            <meta name="title" content={`Sørsport - ${title}`} />
            <meta
                name="keywords"
                content={`sørsport ${tags ? ", " + tags : ""}`}
            />
            <meta name="description" content={description} />
            <meta
                http-equiv="Content-Type"
                content="text/html; charset=utf-8"
            />
            <meta
                property="og:title"
                content={`Sørsport${title ? " - " + title : ""}`}
            />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
        </Helmet>
    );
};

export default SEO;

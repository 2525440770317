import React from "react";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

const InternalOrExternalLink = ({
    children,
    to = "",
    target,
    rel,
    routePrefix = "",
    style,
    className,
}) => {
    if (to === "" || to.startsWith("/")) {
        return (
            <Link
                to={routePrefix ? routePrefix + to : to}
                className={className}
                style={style}
                onClick={() => window.scrollTo(0, 0)}
            >
                {children}
            </Link>
        );
    }
    else if (to.startsWith("#")) {
        return (
            <AnchorLink href={to} className={className} style={{ style }}>
                {children}
            </AnchorLink>
        );
    } else {
        return (
            <a
                href={to}
                target="_blank"
                rel="noreferrer"
                style={style}
                className={className}
            >
                {children}
            </a>
        );
    }
};

export default InternalOrExternalLink;

import React from "react";

const UmbracoTextBlock = ({ c }) => {
    let className = "richtext mx-auto";
    return (
        <>
            {c && (
                <div className={className} style={{ maxWidth: 900 }}
                    dangerouslySetInnerHTML={{
                        __html: c.richText,
                    }}>
                </div>


            )}
        </>
    );
};

export default UmbracoTextBlock;

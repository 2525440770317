import React, { useContext } from 'react';

// Internal components
import Bg from '../components/Bg';
// import Btn from '../components/Btn';
import InternalOrExternalLink from '../components/InternalOrExternalLink';
import AnimateMount from '../components/AnimateMount';
// import VideoIntro from '../components/VideoIntro';
// import LoadingSpinner from '../components/LoadingSpinner';
import SEO from '../components/SEO';

// External components
import { BsArrowRightShort } from 'react-icons/bs';

// Assets
import bgImg from '../assets/home-bg.jpg';

// Contexts
import { UmbracoContext } from '../contexts/Umbraco';

const ImgCol = ({ src, title, to, animFrom, animTo, screenWidth, disabled }) => (
	<AnimateMount from={{ opacity: 0 }} to={{ opacity: 1 }}>
		<div className={`position-relative text-center d-inline-block ${disabled ? "home-grid_item--disabled" : ""}`}>
			<InternalOrExternalLink to={to}>
				<div className='text-center home-grid_item'>
					<div className='home-grid_item__bottom-section'>
						<BsArrowRightShort size='2rem' />
					</div>
					{src && (
						<>
							<div className='home-grid_item__light-sweep'></div>
							<div className='home-grid_item_image-mask mb-4'>
								<img
									src={src + '&width=500'}
									width='100%'
									className='home-grid_item_image'
									alt={title}
								/>
							</div>
							{title && (
								<>
									<svg
										className='curved-text'
										width='100%'
										height='100%'
										viewBox='0 0 100 100'
									>
										<path
											id='curve'
											fill='transparent'
											d='M0,48.8a48.8,48.8,0,0,0,97.6,0'
											vectorEffect='non-scaling-stroke'
										/>
										<text width='500'>
											<textPath xlinkHref='#curve' fill='currentColor'>
												{title}
											</textPath>
										</text>
									</svg>
									<div className='curved-text-margin-bottom'></div>
								</>
							)}
						</>
					)}
				</div>
			</InternalOrExternalLink>
			{/* <Btn
				variant='light'
				to={to}
				className='home-grid_item_btn'
				animFrom={animFrom}
				animTo={animTo}
			>
				{' '}
				<BsArrowRightShort size='2rem' />
			</Btn> */}
			{/* {title && <h1 className="mb-5 h2"></h1>} */}
			{/* {to && screenWidth > 1100 && (
				<Btn
					variant='light'
					to={to}
					className='home-grid_item_btn'
					animFrom={animFrom}
					animTo={animTo}>
					Les mer
				</Btn>
			)} */}
		</div>
	</AnimateMount>
);

const Home = () => {
	const {
		data,
		screenWidth,
		loading,
		// setLoading,
		showIntro,
		introVidEnded,
		// setIntroVidEnded,
		// skipIntro,
	} = useContext(UmbracoContext);
	// const heightRef = useRef(0);
	// const [size, setSize] = useState(null);
	// useEffect(() => {
	//     if (heightRef.current) {
	//         setSize(heightRef.current.clientHeight);
	//     }
	// }, [heightRef, screenWidth]);

	// useEffect(() => {
	//     console.log(introVidEnded ? "video has ended" : "not ended");
	// }, [introVidEnded]);
	// const [height, setHeight] = useState("");
	// useEffect(() => {
	//     setHeight(document.getElementById("home-grid").innerWidth);
	// }, []);
	let circles = Array.isArray(data?.circles) ? data.circles : [data?.circles];
	return (
		<>
			{data &&
				data.pages &&
				data.pages.map((page, i) => (
					<React.Fragment key={'cont-' + page.key}>
						{page.seo && (
							<SEO
								title={page.seo.title}
								description={page.seo.description}
								image={page.seo.image?.url}
							/>
						)}
					</React.Fragment>
				))}

			{/* {showIntro && !skipIntro && (
				<VideoIntro
					className={`${introVidEnded ? 'video-intro--hide' : ''}`}
					reportLoaded={() => setLoading(false)}
					reportEnded={() => setIntroVidEnded(true)}
				/>
			)}
			{loading && showIntro && <LoadingSpinner />} */}
			{/* {loading ? <h1>{"loading"}</h1> : <h1>{"done loading"}</h1>}
            {showIntro ? (
                <h1>{"showing intro"}</h1>
            ) : (
                <h1>{"not showing intro"}</h1>
            )}
            {introVidEnded ? (
                <h1>{"vid ended"}</h1>
            ) : (
                <h1>{"not vid ended"}</h1>
            )} */}

			<AnimateMount trigger={!loading || !showIntro}>
				<Bg
					src={bgImg}
					overlay
					// height={
					//     size < document.body.innerHeight
					//         ? document.body.innerHeight
					//         : size
					// }
					height='100vh'
					fixed
				/>
			</AnimateMount>
			<AnimateMount
				trigger={introVidEnded || !showIntro}
				// delay={showIntro && introVidEnded ? 0.3 : 0}
				from={{ opacity: 0 }}
				to={{ opacity: 1 }}
			>
				<div className='home-wrapper'>
					<div
						className='home-grid'
						id='home-grid'
					// ref={heightRef}
					>
						{circles?.map((circle, i) => circle && (
							<React.Fragment key={'circle-' + circle.key}>
								<ImgCol
									src={circle.featuredImage?.url + '&width=500'}
									title={circle.title}
									to={circle?.link?.url}
									disabled={circle.disabled}
									screenWidth={screenWidth}
									animFrom={{ opacity: 0 }}
									animTo={{ opacity: 1 }}
								/>
							</React.Fragment>
						))}
						{/* {data &&
							data.children &&
							data.children.map((page, i) => (
								<React.Fragment key={'page-' + page.key}>
									{page.featureOnHomePage && (
										<ImgCol
											src={page.thumbnail?.url + '&width=500'}
											title={page.title}
											to={page.url}
											disabled={page.disabled}
											screenWidth={screenWidth}
											animFrom={{ opacity: 0 }}
											animTo={{ opacity: 1 }}
										/>
									)}
								</React.Fragment>
							))} */}
					</div>
				</div>
			</AnimateMount>
		</>
	);
};

export default Home;

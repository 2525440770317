import React, { useContext } from "react";

// Internal components
import decor from "../assets/decoration.svg";
import AnimateMount from "../components/AnimateMount";

// Contexts
import { UmbracoContext } from "../contexts/Umbraco";

const Bg = ({ overlay, src, decoration, height = "100%", fixed }) => {
    const { showIntro } = useContext(UmbracoContext);
    return (
        <>
            {src && (
                <AnimateMount
                    delay={showIntro ? 0.5 : 0}
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}
                >
                    <div
                        className="bg-container"
                        style={{
                            height: height,
                            position: fixed ? "fixed" : "",
                        }}
                    >
                        {decoration && (
                            <div
                                className="decoration"
                                style={{
                                    backgroundImage: `url("${decor}")`,
                                }}
                            />
                        )}
                        {overlay && <div className={`overlay`}></div>}
                        <div
                            className={`bg`}
                            style={{
                                backgroundImage: `url("${src}")`,
                            }}
                        ></div>
                    </div>
                </AnimateMount>
            )}
        </>
    );
};

export default Bg;

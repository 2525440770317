import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// Routes
import Routes from './Routes';

// Internal components
import Nav from './components/Nav';
import AnimateMount from './components/AnimateMount';
import Footer from './components/Footer';

// Contexts
import { UmbracoContext } from './contexts/Umbraco';


// External components
import Hotkeys from 'react-hot-keys';

const App = () => {
	const {
		loading,
		showIntro,
		introVidEnded,
		// setSkipIntro,
		// setLoading,
		// setIntroVidEnded,
		// skipIntro,
		pageId,
		data,
	} = useContext(UmbracoContext);

	useEffect(() => {
		if (
			window.location.hostname === 'localhost' ||
			window.location.hostname === '127.0.0.1'
		) {
			// dev code
			if (window.location.pathname === '/build') {
				// Redirect from build to home
				window.location = '/';
			}
		} else {
			// production code
		}
	}, []);

	return (
		<>
			<Hotkeys
				keyName='alt+shift+u'
				allowRepeat={true}
				onKeyDown={() =>
					window
						.open(
							`
							${process.env.REACT_APP_PROXY}
							${pageId
								? `/umbraco#/content/content/edit/${pageId}`
								: '/umbraco'
							}`,
							'_blank'
						)
						.focus()
				}></Hotkeys>
			<Hotkeys
				keyName='alt+shift+g'
				allowRepeat={true}
				onKeyDown={() =>
					window
						.open(
							`https://github.com/aksellsor/07_cra_sorsport/actions`,
							'_blank'
						)
						.focus()
				}></Hotkeys>
			<div
				className='bg-radial'
				style={{
					background: `radial-gradient(${data?.backgroundGradient?.from}, ${data?.backgroundGradient?.to}, transparent)`,
				}}
			></div>
			<Router>
				{/* <div
					className={`${
						showIntro && !skipIntro && !introVidEnded ? '' : 'skip-intro-hide'
					} btn btn-secondary skip-intro rounded-pill`}
					onClick={() => {
						setSkipIntro(true);
						setLoading(false);
						setIntroVidEnded(true);
					}}
				>
					Skip intro
				</div> */}
				<AnimateMount
					trigger={!loading || !showIntro}
					from={{ opacity: 0 }}
					to={{ opacity: 1 }}
				>
					<Nav />
				</AnimateMount>
				<Routes />
				<AnimateMount
					trigger={(!loading && introVidEnded) || !showIntro}
					from={{ opacity: 0 }}
					to={{ opacity: 1 }}
					style={{ marginTop: 'auto' }}
				>
					<Footer />
				</AnimateMount>
			</Router>
		</>
	);
};

export default App;

import React, { useContext, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

// Routes
import Home from './routes/Home';
import Page from './routes/Page';

// Contexts
import { UmbracoContext } from './contexts/Umbraco';

// External components
import { AnimatePresence, motion } from 'framer-motion';
import ScrollRestoration from 'react-scroll-restoration';
import CMDK from 'components/CMDK';

const Redir = ({ content, children }) => {
	let redir = content?.redirectTo?.url;
	if (redir) {
		window.location.href = redir;
	}
	else {
		return children;
	}
};

const Routes = () => {
	const {
		data,
		setRouteHistory,
		routeHistory,
		// showIntro,
		// setShowIntro,
		// skipIntro,
	} = useContext(UmbracoContext);
	const location = useLocation();
	const pathname = location.pathname;

	useEffect(() => {
		//Fixed dependancy issue by including SetRouteHistory and routeHistory and adding a conditional to avoid an infinate loop. ;)
		if (routeHistory[routeHistory.length - 1] !== pathname) {
			setRouteHistory([...routeHistory, pathname]);
		}
	}, [pathname, setRouteHistory, routeHistory]);

	// useEffect(() => {
	//     if (pathname && pathname === "/") {
	//         !showIntro && setShowIntro(true);
	//     }
	// }, [pathname, setShowIntro, showIntro]);
	// useEffect(() => {
	//     if (pathname === "/" && routeHistory.length === 1 && !skipIntro) {
	//         setShowIntro(true);
	//     } else {
	//         setShowIntro(false);
	//     }
	// }, [routeHistory, setShowIntro, pathname, skipIntro]);
	return (
		//Removed exitBeforeEnter because of a warning
		<AnimatePresence initial={false}>
			{Array.isArray(data?.children) && <CMDK routes={data?.children} />}
			<ScrollRestoration />
			<Switch location={location} key={location.pathname}>
				{Array.isArray(data?.children) &&
					data?.children.map((page) => (
						<Route path={page.url} exact key={page.key}>
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.1 }}>
								<Redir content={page}><Page content={page} /></Redir>
							</motion.div>
						</Route>
					))}
				<Route path='/' exact>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.1 }}>
						<Home />
					</motion.div>
				</Route>
			</Switch>
		</AnimatePresence>
	);
};

export default Routes;

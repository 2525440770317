import React, { useContext } from 'react';
import InternalOrExternalLink from './InternalOrExternalLink';

import { UmbracoContext } from 'contexts/Umbraco';
import UmbracoTextBlock from './UmbracoTextBlock';

const ImageLinks = ({ c, className }) => {
	const { screenWidth } = useContext(UmbracoContext);
	if (!c) return null;
	let images = Array.isArray(c.imagesArray) ? c.imagesArray : [c.imagesArray];
	let width = '150px';
	if (c.imageWidth) {
		width = parseInt(c.imageWidth) < screenWidth ? c.imageWidth : '100%';
	}
	return (
		<div className={`image-links ${className}`}>
			{/* <pre>{JSON.stringify(c, null, 1)}</pre> */}
			{images && images?.length > 0 ? (
				<>
					{images?.map(
						(item) =>
							<>
								{item?.type === "imageLink" &&
									<>
										{item?.img && (
											<InternalOrExternalLink
												to={item.link?.url || '/'}
												className='image-links__link'
											>
												{item.img.umbracoExtension === 'svg' ? (
													<img
														className='image-links__link__image'
														style={{ width: `${width || ''}` }}
														src={item.img.umbracoFile}
														alt={item.img.label}
													/>
												) : (
													<img
														className='image-links__link__image'
														style={{ width: `${width || ''}` }}
														src={item.img.url + `&width=${width}`}
														alt={item.img.label}
													/>
												)}
											</InternalOrExternalLink>
										)}
									</>}
								{item?.type === "textBlock" && <UmbracoTextBlock c={item} />}
							</>
					)}
				</>
			) : (
				''
			)}
		</div>
	);
};

export default ImageLinks;

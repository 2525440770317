import React from "react";

// External components
import { motion, AnimatePresence } from "framer-motion";

const AnimateMount = ({
    trigger = true,
    children,
    from,
    to,
    exit = false,
    duration = 0.4,
    delay,
    style,
}) => {
    return (
        <>
            <AnimatePresence>
                {trigger && (
                    <motion.div
                        style={style}
                        initial={from || { opacity: 0.5 }}
                        animate={to || { opacity: 1 }}
                        exit={exit ? from || { opacity: 0.5 } : {}}
                        transition={{ duration: duration, delay: delay }}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default AnimateMount;

import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

// Internal components
import Logo from '../components/Logo';
import InternalOrExternalLink from '../components/InternalOrExternalLink';
import AnimateMount from '../components/AnimateMount';

// Contexts
import { UmbracoContext } from '../contexts/Umbraco';

// External components
import FadeIn from 'react-fade-in';
// import Hamburger from 'hamburger-react';
import Grass from './Grass';

const Nav = () => {
	const { data, showIntro, introVidEnded, getMediaPath } = useContext(UmbracoContext);
	const [isOpen, setOpen] = useState(false);
	// const [left, setLeft] = useState([]);
	// const [right, setRight] = useState([]);
	// React.useEffect(() => {
	// 	let newArray = [];
	// 	data?.children.map((p) => p.navCategory && newArray.push(p.navCategory));
	// 	const uniqueArray = newArray.filter((thing, index) => {
	// 		const _thing = JSON.stringify(thing);
	// 		return (
	// 			index ===
	// 			newArray.findIndex((obj) => {
	// 				return JSON.stringify(obj) === _thing;
	// 			})
	// 		);
	// 	});
	// 	if (uniqueArray.length > 1) {
	// 		setLeft([...uniqueArray.slice(0, uniqueArray.length / 2)]);
	// 		setRight([...uniqueArray.slice(uniqueArray.length / 2)]);
	// 	} else {
	// 		setLeft([...uniqueArray]);
	// 	}
	// }, [data?.children]);
	const leftMenuItems = data?.leftMenuItems || [];
	const rightMenuItems = data?.rightMenuItems || [];
	return (
		<nav
			className={`nav ${isOpen ? 'nav-open' : ''} `}
			onClick={() => isOpen && setOpen(false)}
		>
			<AnimateMount
				trigger={!showIntro || (showIntro && introVidEnded)}
				// delay={showIntro && introVidEnded ? 0.8 : 0}
				from={{ opacity: 0, translateX: 50 }}
				to={{ opacity: 1, translateX: 0 }}
			>
				<div className='nav_left'>
					{leftMenuItems?.map((item, index) => (
						<React.Fragment key={'int-ex-left' + index}>
							<InternalOrExternalLink
								to={item.link?.url}
								className='nav_item hover-scale'
							>
								<span className='nav_item_light-sweep' />
								{item.icon && <img src={getMediaPath(item.icon)} className='nav_item_icon' alt={item.link?.label} />}
								{item.link?.label}
							</InternalOrExternalLink>
						</React.Fragment>
					))}
				</div>
			</AnimateMount>
			<Link
				to='/'
				className={`nav_item nav_logo
                ${showIntro && 'nav_logo-initial'}
                ${introVidEnded && 'nav_logo-animate'}
                
                `}
			>
				<Logo
					className={`hover-scale ${!showIntro || (showIntro && introVidEnded)
						? ''
						: 'nav_logo-no-interaction'
						}`}
				/>
			</Link>
			<AnimateMount
				trigger={!showIntro || (showIntro && introVidEnded)}
				// delay={showIntro && introVidEnded ? 0.8 : 0}
				from={{ opacity: 0, translateX: -50 }}
				to={{ opacity: 1, translateX: 0 }}
			>
				<div className='nav_right'>
					{rightMenuItems?.map((item, index) => (
						<React.Fragment key={'int-ex-right' + index}>
							<InternalOrExternalLink
								to={item.link?.url}
								className='nav_item hover-scale'
							>
								<span className='nav_item_light-sweep' />
								{item.icon && <img src={getMediaPath(item.icon)} className='nav_item_icon' alt={item.link?.label} />}
								{item.link?.label}
							</InternalOrExternalLink>
						</React.Fragment>
					))}
				</div>
			</AnimateMount>
			{isOpen && (
				<>
					{/* <div className='nav__mobile-overlay'></div> */}
					<FadeIn className='nav__mobile-menu'>
						{[...leftMenuItems, ...rightMenuItems].map((item, index) => (
							<React.Fragment key={'mob-item' + index}>
								<InternalOrExternalLink
									to={item.link?.url}
									className='nav__mobile-menu__item'
								>
									{item.icon && <img src={getMediaPath(item.icon)} className='nav__mobile-menu__item__icon' alt={item.link?.label} />}
									{item.link?.label}
								</InternalOrExternalLink>
							</React.Fragment>
						))}
					</FadeIn>
				</>
			)}
			<div className='nav__mobile-grass'>
				<Grass forcePause={!isOpen} />
			</div>
			<div className='nav_hamburger' onClick={() => {
				setOpen(o => !o);
			}}>
				<AnimateMount
					trigger={!showIntro || (showIntro && introVidEnded)}
					// delay={showIntro && introVidEnded ? 0.3 : 0}
					from={{ opacity: 0, translateX: -50 }}
					to={{ opacity: 1, translateX: 0 }}
				>
					<div className={`hover-scale nav_hamburger_icon ${isOpen ? "nav_hamburger_icon--open" : ""}`}
					>
						{/* 2 */}
						<span className='hb1'></span>
						<span className='hb2'></span>

						{/* 4 */}
						<span className='hb3'></span>
						<span className='hb4'></span>
						<span className='hb5'></span>
						<span className='hb6'></span>

						{/* 2 */}
						<span className='hb7'></span>
						<span className='hb8'></span>
						{/* <Hamburger toggled={isOpen} toggle={setOpen} /> */}
					</div>
				</AnimateMount>
			</div>
		</nav>
	);
};

export default Nav;

// import React, { useState, useEffect, useRef } from 'react';
// import Player from '@vimeo/player';

const Grass = ({ className, forcePause = false }) => {
	// const [player, setPlayer] = useState(null);
	// const ifr = useRef();
	// useEffect(() => {
	// 	setPlayer(new Player(ifr.current));
	// }, [ifr]);

	// useEffect(() => {
	// 	if (forcePause) {
	// 		player?.pause();
	// 		// console.log('pausing');
	// 	} else {
	// 		player?.play();
	// 		// console.log('playing');
	// 	}
	// }, [forcePause, player]);
	return '';
	// return (
	// 	<iframe
	// 		ref={ifr}
	// 		className={className}
	// 		src='https://player.vimeo.com/video/747569503?h=e7665baf4d&amp;title=0&amp;byline=0&amp;portrait=0&amp;muted=1&amp;autoplay=1&amp;autopause=0&amp;controls=0&amp;loop=1&amp;background=1&amp;quality=720p'
	// 		frameBorder='0'
	// 		title='grass'
	// 		allow='autoplay; fullscreen; picture-in-picture'
	// 	></iframe>
	// );
};

export default Grass;

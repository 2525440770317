import React, { useContext, useRef, useState, useEffect, useCallback } from 'react';

// Internal components
// import Btn from '../components/Btn';
import AnimateMount from '../components/AnimateMount';
import Bg from '../components/Bg';
import Components from '../components/Components';
import SEO from '../components/SEO';

// Assets
import decorationLight from '../assets/decoration-light.svg';

// Contexts
import { UmbracoContext } from '../contexts/Umbraco';

// External components
import { motion } from 'framer-motion';
import { Container } from 'react-bootstrap';
import Cta from 'components/Cta';
import { Suspense } from 'react';
const Sus = ({ children }) => <Suspense fallback={<center>Loading...</center>}>{children}</Suspense>;
const Page = ({ content }) => {
	let components = content.dynamicContent;
	const { data, screenWidth, setPageId } = useContext(UmbracoContext);
	const headerDiv = useRef(null);
	const [size, setSize] = useState(null);
	useEffect(() => {
		if (headerDiv.current !== undefined) {
			setSize(headerDiv.current?.clientHeight);
		}
	}, [headerDiv, screenWidth]);
	const updatePageId = useCallback(
		(val) => {
			setPageId(val);
		},
		[setPageId],
	);

	useEffect(() => {
		updatePageId(content.id);
	}, [content.id, updatePageId]);

	let bgWidth = 0;
	if (screenWidth > 1600) {
		bgWidth = 1920;
	} else if (screenWidth < 1600 && screenWidth > 1000) {
		bgWidth = 1200;
	} else if (screenWidth < 1000) {
		bgWidth = 800;
	}

	return (
		<>
			{window.location.pathname !== '/' && data?.pageBg && (
				<img
					src={data?.pageBg?.url + `&width=${bgWidth}&quality=65`}
					className='page-bg'
					alt=''
					title=''
				/>
			)}
			<div className='page'>
				<SEO
					title={content.seo?.title || content.title}
					description={content.seo?.description || ''}
					image={content.seo?.image?.url || ''}
				/>
				{
					<motion.header
						className='text-center page_header position-relative'
						ref={headerDiv}
					// style={{ backgroundImage: `url("${header.bg}")` }}
					>
						{content.headerBg && (
							<Bg
								src={content.headerBg.url}
								decoration
								height={size + 'px'}
								overlay
							/>
						)}
						{/* {data.headerDecoration && (
	                        <motion.img
	                            className="page_header_decoration"
	                            src={data.headerDecoration}
	                            alt="decoraton"
	                            initial={{ translateX: -50 }}
	                            animate={{ translateX: 0 }}
	                            // exit={{ translateX: -100 }}
	                            transition={{
	                                ease: "easeInOut",
	                                duration: 1,
	                            }}
	                        />
	                    )} */}
						<Container className='page_header_content'>
							{content.headerTitle && (
								<AnimateMount
									from={{ scale: 1.1 }}
									to={{ scale: 1 }}
									duration={1}
								>
									<h1 className='text-uppercase lg d-inline-block page_header_title'>
										{content.headerTitle}
										<hr />
									</h1>
								</AnimateMount>
							)}
							{content.headerSubtitle && (
								<AnimateMount
									from={{ translateX: -30, opacity: 0 }}
									to={{ translateX: 0, opacity: 1 }}
									// delay={0.3}
									duration={0.5}
								>
									<h2 className='mb-5'>{content.headerSubtitle}</h2>
								</AnimateMount>
							)}
							{content.cta && content.cta.url && (
								<center className='mb-3'>
									<Cta to={content.cta.url}>{content.cta.label}</Cta>
								</center>
							)}
						</Container>
					</motion.header>
				}
				<section className='page_content-wrapper'>
					<img
						className='page_decoration'
						src={decorationLight}
						alt='decoration'
						draggable='false'
					/>
					<div className='page_content'>
						<Sus>
							<Components
								components={Array.isArray(components) ? components : [components]}
							/>
						</Sus>
					</div>
				</section>
			</div>
		</>
	);
};

export default Page;

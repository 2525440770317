import React, { useContext, useRef } from 'react';

// Internal components
// import Btn from '../components/Btn';
// import AnimateMount from '../components/AnimateMount';
// import Components from '../components/Components';

// Contexts
import { UmbracoContext } from '../contexts/Umbraco';

// External components
import parse from 'html-react-parser';
import ImageLinks from './ImageLinks';
// import Grass from './Grass';
// import useIntersection from 'react-useintersection';

const Footer = () => {
	const grass = useRef();
	// const [setNode, anchorObserver] = useIntersection();
	const { data, screenWidth } = useContext(UmbracoContext);
	// useEffect(() => {
	// 	setNode(grass.current);
	// });
	let footerBg = data?.footerBg;
	let bgWidth = '';

	if (screenWidth > 1600) {
		bgWidth = 1920;
	} else if (screenWidth < 1600 && screenWidth > 1000) {
		bgWidth = 1200;
	} else if (screenWidth < 1000) {
		bgWidth = 800;
	}

	// useEffect(() => {
	// 	console.log(anchorObserver?.isIntersecting);
	// }, [anchorObserver]);

	return (
		<>
			{data && (
				<footer
					className='footer d-flex align-items-center justify-content-center py-5 flex-column text-center'
					ref={grass}
				>
					<div
						className='footer__bg'
						style={{
							backgroundImage: `url(${footerBg?.url + `&width=${bgWidth}&quality=65`
								})`,
						}}
					></div>
					{/* {footer && (
					<>
						{footer.title && (
							<AnimateMount from={{ scale: 1.1 }} to={{ scale: 1 }} duration={1}>
								<h1 className='text-uppercase lg'>{footer.title}</h1>
							</AnimateMount>
						)}
						{footer.subTitle && (
							<AnimateMount
								from={{ translateX: -30, opacity: 0 }}
								to={{ translateX: 0, opacity: 1 }}
								// delay={0.3}
								duration={0.5}
							>
								<h2 className='mb-5'>{footer.subTitle}</h2>
							</AnimateMount>
						)}
						{footer.cta && footer.cta.to && (
							<Btn to={footer.cta.to}>{footer.cta.label}</Btn>
						)}
					</>
				)} */}
					<div className='footer__text'>{typeof data?.footerText === "string" && parse(data.footerText)}</div>
					<div className='footer__content'>
						{Array.isArray(data.footerLogos) && <ImageLinks
							className='footer__logos '
							c={{ imagesArray: data.footerLogos, imageWidth: '200px' }}
						/>}
					</div>
					<ImageLinks c={data.footerLogosExtra} />
					{/* <Components
					components={Array.isArray(data.footer) ? data.footer : [data.footer]}
				/> */}
					<div style={{ marginTop: "2rem", marginBottom: "2rem" }}><a href="https://sorsport.no/personvernerklaering">Personvern</a></div>
				</footer>
			)}
			{/* <Grass
				className='footer__grass'
				forcePause={!anchorObserver.isIntersecting}
			/> */}
		</>
	);
};

export default Footer;

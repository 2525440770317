import React, { Suspense } from 'react';

// Internal components
// import Slider from '../components/Slider';
// import Btn from '../components/Btn';
// import TextBlockImageRight from '../components/TextBlockImageRight';
// import UmbracoCard from '../components/UmbracoCard';
// import UmbracoTextBlock from '../components/UmbracoTextBlock';
// import TextBlockImageLeft from '../components/TextBlockImageLeft';
// import TextBlockForm from '../components/TextBlockForm';
// import ImageLinks from '../components/ImageLinks';
// import FAQ from '../components/FAQ';

// External components
import { Container, Row, Col } from 'react-bootstrap';
import { ImCalendar } from 'react-icons/im';
import { FaMapMarkerAlt, FaCoins } from 'react-icons/fa';
import SVG from 'react-inlinesvg';

// Assets
import quote from '../assets/icons/quote.svg';
const RoundImageTextRows = React.lazy(() => import('../components/RoundImageTextRows'));
const ImageLinks = React.lazy(() => import('../components/ImageLinks'));
const TextBlockForm = React.lazy(() => import('../components/TextBlockForm'));
const TextBlockImageLeft = React.lazy(() => import('../components/TextBlockImageLeft'));
const UmbracoTextBlock = React.lazy(() => import('../components/UmbracoTextBlock'));
const UmbracoCard = React.lazy(() => import('../components/UmbracoCard'));
const TextBlockImageRight = React.lazy(() => import('../components/TextBlockImageRight'));
const Btn = React.lazy(() => import('../components/Btn'));
const Slider = React.lazy(() => import('../components/Slider'));
const FAQ = React.lazy(() => import('../components/FAQ'));
const Lineup = React.lazy(() => import('../components/Lineup'));
const formatDate = (start, end) => {
	let sDay = start?.getDate();
	let eDay = end?.getDate();
	let sMonth = start?.getMonth();
	let eMonth = end?.getMonth();
	let months = ["jan", "feb", "mar", "apr", "mai", "jun", "jul", "aug", "sep", "okt", "nov", "des"];

	if (sMonth === eMonth) {
		return `${sDay}. - ${eDay}. ${months[sMonth]}`;
	} else {
		return `${sDay}. ${months[sMonth]} - ${eDay}. ${months[eMonth]}`;
	}
};
const Sus = ({ children }) => <Suspense fallback={<center>Loading...</center>}>{children}</Suspense>;
const DateBox = ({ startDate, endDate }) => {
	let start = startDate ? new Date(startDate) : null;
	let end = endDate ? new Date(endDate) : null;
	let date = start && end ? formatDate(start, end) : null;
	return (

		<span>
			{date}
		</span>
	);
};
const Components = ({ components }) => {
	return (
		<>
			{Array.isArray(components) &&
				components?.map((c, index) => (
					<React.Fragment key={'comp-' + c.key}>
						{c.alias === 'lineup' && (
							<Sus>
								<Container
									className={`component-wrapper ${index === 0 ? 'pt-5' : 'pt-0'
										}`}
									fluid='lg'
								>
									<Lineup c={c} />
								</Container>
							</Sus>
						)}
						{c.type === 'form' && (
							<Sus>
								<Container
									className={`component-wrapper ${index === 0 ? 'pt-0 pt-md-5' : ''
										}`}
									fluid='lg'
								>
									<TextBlockForm c={c} />
								</Container>
							</Sus>
						)}

						{c.type.toLowerCase() === 'carousel' &&
							c.images &&
							Array.isArray(c.images) && (
								<Container className='component-wrapper px-0' fluid='lg'>
									{c.images && c.images.length > 0 && (
										<Slider images={c.images} />
									)}
								</Container>
							)}
						{c.type.toLowerCase() === 'quote' && (
							<Container style={{ maxWidth: 900 }}>
								<Row
									className='justify-content-center pb-5 pt-4 mx-0'
									style={{ gap: '2rem' }}
								>
									<Col xs='auto'>
										<SVG src={quote} />
									</Col>
									<Col>
										<div
											className={`richtext h2 mx-auto  ${c.centered && 'text-md-center'
												}`}
											dangerouslySetInnerHTML={{
												__html: c.richText,
											}}
										/>
									</Col>
								</Row>
							</Container>
						)}
						{c.type.toLowerCase() === 'pricedatelocation' && (
							<Container className='component-wrapper'>
								{c.richText && (
									<Row>
										<Col className='px-4'>
											<div
												className={`richtext mx-auto  ${c.centered && 'text-md-center'
													}`}
												style={{ maxWidth: 1000 }}
												dangerouslySetInnerHTML={{
													__html: c.richText,
												}}
											/>
										</Col>
									</Row>
								)}
								{(c.price || c.date || c.location) && (
									<Row
										className='justify-content-center text-md-center py-4 mt-2 mx-0 px-0 px-sm-5 mb-4'
										style={{ columnGap: '3rem' }}
									>
										{c.price && (
											<Col xs={12} md='auto' className=' text-left mb-4 d-flex'>
												<FaCoins size='2rem' className='me-3 text-primary' />{' '}
												<span
													dangerouslySetInnerHTML={{
														__html: c.price,
													}}
												/>
											</Col>
										)}
										{(c.startDate || c.endDate) && (
											<Col xs={12} md='auto' className=' text-left mb-4 d-flex'>
												<ImCalendar size='2rem' className='me-3 text-primary' />{' '}
												<DateBox startDate={c.startDate} endDate={c.endDate} />
												{/* <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: c.date,
                                                    }}
                                                /> */}
											</Col>
										)}
										{c.location && (
											<Col xs={12} md='auto' className=' text-left mb-4 d-flex'>
												<FaMapMarkerAlt
													size='2rem'
													className='me-3 text-primary'
												/>
												<span
													dangerouslySetInnerHTML={{
														__html: c.location,
													}}
												/>
											</Col>
										)}
										{c.richTextBottom && (
											<Col xs={12} className='px-4 '>
												<div
													className={`richtext mx-auto mb-4  ${c.centered && 'text-md-center'
														}`}
													style={{ maxWidth: 1000 }}
													dangerouslySetInnerHTML={{
														__html: c.richTextBottom,
													}}
												/>
											</Col>
										)}
									</Row>
								)}
								{c.cta && c.cta.url && (
									<Sus>
										<Row className='justify-content-center text-center'>
											<Btn to={c.cta.url}>{c.cta.label}</Btn>
										</Row>
									</Sus>
								)}
							</Container>
						)}
						{c.type.toLowerCase() === 'textblock' && !c.variation && (
							<Sus>
								<Container className='component-wrapper' fluid='lg'>
									<UmbracoTextBlock c={c} />
								</Container>
							</Sus>
						)}
						{c.type.toLowerCase() === 'imagelinks' && (
							<Sus>
								<Container className='component-wrapper' fluid='lg'>
									<ImageLinks c={c} />
								</Container>
							</Sus>
						)}
						{c.type.toLowerCase() === 'faq' && (
							<Sus>
								<Container className='component-wrapper' fluid='lg'>
									<FAQ c={c} />
								</Container>
							</Sus>
						)}
						{c.type.toLowerCase() === 'textblockwithimage' &&
							c.variation &&
							c.variation.toLowerCase() === 'right' && (
								<Sus>
									<Container className='component-wrapper' fluid='lg'>
										<TextBlockImageRight c={c} />
									</Container>
								</Sus>
							)}
						{c.type.toLowerCase() === 'textblockwithimage' &&
							c.variation &&
							c.variation.toLowerCase() === 'left' && (
								<Sus>
									<Container className='component-wrapper' fluid='lg'>
										<TextBlockImageLeft c={c} />
									</Container>
								</Sus>
							)}
						{c.type.toLowerCase() === 'cards' && !c.carousel && c.items?.length > 0 && !c.showAsRoundImageTextRows && (
							<Sus>
								<Container className='component-wrapper'>
									<div
										className={`richtext h2 mx-auto text-center px-4 mb-0 mb-md-5`}
										dangerouslySetInnerHTML={{
											__html: c.richText,
										}}
									/>
									<Row
										className={`justify-content-center mx-0 px-4 ${c.horizontal ? 'cards--horizontal' : ''
											}`}
									>
										{c.items?.map((card) => (
											<UmbracoCard
												key={'ubc-' + card.key}
												card={card}
												horizontal={c.horizontal}
											/>
										))}
									</Row>
								</Container>
							</Sus>
						)}
						{c.type.toLowerCase() === 'cards' && !c.carousel && c.showAsRoundImageTextRows && (
							<Sus>
								<Container className='component-wrapper' fluid='lg'>
									<Row>
										{c.richText && (
											<div
												className={`richtext mx-auto px-4 mb-0 mb-md-5 ${c.centered && 'text-md-center'
													}`}
												style={{ maxWidth: 900 }}
												dangerouslySetInnerHTML={{
													__html: c.richText,
												}}
											/>
										)}
										<RoundImageTextRows items={c.items} />
									</Row>
								</Container>
							</Sus>
						)}
						{c.type.toLowerCase() === 'cards' && c.carousel && (
							<Sus>
								<Container className='component-wrapper' fluid='lg'>
									<Row>
										{c.richText && (
											<div
												className={`richtext mx-auto px-4 mb-0 mb-md-5 ${c.centered && 'text-md-center'
													}`}
												style={{ maxWidth: 900 }}
												dangerouslySetInnerHTML={{
													__html: c.richText,
												}}
											/>
										)}
										<Slider cards={c.items} />
									</Row>
								</Container>
							</Sus>
						)}
						{c.type.toLowerCase() === 'imagecomponent' && c.image && (
							<>
								<img
									src={c.image.url}
									className='w-100 object-fit-cover shadow'
									style={{ height: 800 }}
									alt={c.image.altText || ''}
								/>
							</>
						)}
						{c.type.toLowerCase() === 'row' && (
							<Container fluid='lg' className="pt-0 pb-0">
								<Row>
									{(Array.isArray(c.columns) ? c.columns : [c.columns]).map(
										(col, index) => (
											<Col
												key={c.key + '-col-' + index}
												xs={c.columns.length > 1 ? 6 : 12}
											>
												<Components
													components={
														Array.isArray(col.components)
															? col.components
															: [col.components]
													}
												/>
											</Col>
										)
									)}
								</Row>
							</Container>
						)}
					</React.Fragment>
				))}
		</>
	);
};

export default Components;

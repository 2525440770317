import { useContext, useEffect, useRef, useState } from 'react';

import "./CMDK.css";
import { useHistory } from 'react-router-dom';
import {
    HiDocument,
    HiHome,
} from "react-icons/hi";

import { FixedSizeList as List } from "react-window";
import {
    SiUmbraco
} from "react-icons/si";
import { UmbracoContext } from '../contexts/Umbraco';

const goToUmbraco = (id) => {
    if (window.location.hostname === "localhost") {
        window
            .open(
                `${id
                    ? `https://sia.no/umbraco#/content/content/edit/${id
                    }`
                    : "https://sia.no/umbraco"
                }`,
                "_blank"
            )
            .focus();
    } else {

        window
            .open(
                `${id
                    ? `/umbraco#/content/content/edit/${id
                    }`
                    : "/umbraco"
                }`,
                "_blank"
            )
            .focus();
    }
};
const Row = ({ index, style, data }) => {
    const { items, currentItemIndex, updateIndex } = data;
    let item = items[index];
    const history = useHistory();
    let url = item.url;
    return (
        <div style={style}>
            <div
                data-id={item.id}
                // data-url={url}
                onClick={(e) => {
                    history.push(url);
                    updateIndex(index + 1);
                }}
                className={`cmdk-item
                ${currentItemIndex === index + 1 ? "cmdk-item--selected" : ""}
                ${window.location.pathname === item.url ? "current" : ""}`}
            >
                <span style={{ opacity: 0.5 }}>⏎</span>
                <div>{(url === "/" || url === "/en") ? <HiHome /> : <HiDocument />}</div>
                <div className='cmdk-item__label'>{item.title}</div>
                <div style={{ display: "flex", marginLeft: "auto", gap: ".5rem", alignItems: "center" }}>

                    <span style={{ opacity: 0.5 }} className='umb-btn-arrow'>
                        →
                    </span>
                    <div className='umb-btn'
                        onClick={() => {
                            goToUmbraco(item.id);
                        }}>
                        <SiUmbraco />
                    </div>
                </div>
            </div>
        </div>
    );
};
const CMDK = ({ routes }) => {
    const [open, setOpen] = useState(false);
    // Toggle the menu when ⌘K is pressed

    const { pageId
    } = useContext(UmbracoContext);

    // const rows = useRef(null);
    // useEffect(() => {
    //     if (rows.current) {
    //         setHeight(rows.current.clientHeight);
    //     }
    // }, []);

    const [filtered, setFiltered] = useState(routes);
    const [search, setSearch] = useState(null);
    useEffect(() => {

        if (search?.length > 0 && routes?.length > 0) {
            let f = routes?.filter(r => r.title.toLowerCase().includes(search));
            setFiltered(f);
        }
        if (!search?.length > 0) {
            setFiltered(routes);
        }
    }, [search, routes]);

    const dialogRef = useRef();
    useEffect(() => {
        const dialog = dialogRef.current;
        const onClick = (event) => {
            if (event.target === dialog) {
                setOpen(false);
            }
        };
        const keyDown = (e) => {
            if (e.key === "Escape") {
                setOpen(false);
            }

            if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
                setOpen(true);
            }
        };
        dialog.addEventListener("click", onClick);
        window.addEventListener("keydown", keyDown);
        return () => {
            dialog.removeEventListener("click", onClick);
            window.removeEventListener("keydown", keyDown);
        };
    }, [dialogRef]);

    const inputRef = useRef();
    useEffect(() => {
        if (open && inputRef.current) {
            inputRef.current.focus();
        }
    }, [open]);



    const listRef = useRef();
    const [currentItemIndex, setCurrentItemIndex] = useState(0);


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (open) {
                if (event.key === "Enter") {
                    let selected = document.querySelector(`.cmdk-item--selected`);
                    selected.click();
                }
                if (event.key === "ArrowRight") {
                    let selected = document.querySelector(`.cmdk-item--selected`);
                    let dataId = selected?.getAttribute("data-id");

                    if (dataId) {
                        goToUmbraco(dataId);
                    }
                }
                if (event.key === "ArrowDown") {
                    setCurrentItemIndex((prevIndex) => {
                        let newIndex = prevIndex + 1;
                        if (newIndex > filtered?.length) return 0;
                        else return newIndex;
                    });
                } else if (event.key === "ArrowUp") {
                    setCurrentItemIndex((prevIndex) => prevIndex - 1 < 0 ? 0 : prevIndex - 1);
                }
            }
        };
        if (open) {
            window.addEventListener("keydown", handleKeyDown);
        } else {
            window.removeEventListener("keydown", handleKeyDown);
        }
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [open, filtered]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollToItem(currentItemIndex, "auto");
            // "auto" | "smart" | "center" | "end" | "start"
        }
    }, [currentItemIndex]);

    useEffect(() => {
        setCurrentItemIndex(0);
    }, [open]);
    useEffect(() => {
        if (!search?.length > 0) {
            setCurrentItemIndex(0);
        }
    }, [search]);

    return (
        <>

            <div
                ref={dialogRef}
                className={`cmdk-dialog ${open ? "cmdk-dialog--open" : ""}`}
            >
                <div className='cmdk-box'>
                    <input
                        ref={inputRef}
                        className='cmdk-input'
                        placeholder='Søk'
                        onInput={e => {
                            setSearch(e.target.value?.toLowerCase());
                        }}
                    />

                    {filtered?.length === 0 ? <span style={{ opacity: 0.5 }}><center>No results found.</center></span> :
                        <div
                            className={`cmdk-item--first cmdk-item ${currentItemIndex === 0 ? "cmdk-item--selected" : ""}`}
                            onClick={(e) => {
                                goToUmbraco(pageId);
                            }}
                        >

                            {/* <span style={{ opacity: 0.5 }}>⏎</span> */}
                            <SiUmbraco color="#3644a7" />
                            <div className='cmdk-item__label'>Rediger denne siden i Umbraco</div>

                            <span style={{ opacity: 0.5, marginLeft: "auto" }}>⏎</span>
                            {/* <div style={{ marginLeft: "auto", opacity: 0.5 }}><small>ALT+SHIFT+U</small></div> */}
                        </div>
                    }
                    <List
                        itemCount={filtered?.length}
                        itemSize={35}
                        itemData={{ items: filtered, currentItemIndex, updateIndex: setCurrentItemIndex }}
                        ref={listRef}
                        height={250}
                        // height={(filtered?.length + 1) * 35 < 393 ? (filtered?.length + 1) * 35 : 393}
                        width="100%"
                    >
                        {Row}
                    </List>
                </div>
            </div >
        </>
    );
};

export default CMDK;
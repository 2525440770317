import React, { createContext, useState, useEffect, useCallback } from "react";

export const UmbracoContext = createContext();
export const UmbracoProvider = ({ children }) => {
    const [data, setData] = useState();
    const [pageId, setPageId] = useState();
    useEffect(() => {
        const getData = async () => {
            const request = await fetch("/headless/data");
            const response = await request.json();
            setLoading(false);
            setData(response);
            // console.log(response);
        };
        getData();
    }, []);

    const [formData, updateFormData] = useState({});

    /* ********************************** */
    /*            MEDIA QUERIES           */
    /* ********************************** */
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const updateSize = () => {
        setScreenWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", updateSize);
    }, []);
    const [loading, setLoading] = useState(true);
    const [routeHistory, setRouteHistory] = useState([]);
    const [introVidEnded, setIntroVidEnded] = useState(false);
    const [showIntro, setShowIntro] = useState(false);
    const [skipIntro, setSkipIntro] = useState(false);


    const getMediaPath = useCallback(
        (obj) => {
            if (obj?.type === "File") return obj.umbracoFile;
            else return obj?.url?.replace(/\?.*/g, "");
        },
        [],
    );
    return (
        <UmbracoContext.Provider
            value={{
                data,
                screenWidth,
                formData,
                updateFormData,
                loading,
                setLoading,
                routeHistory,
                setRouteHistory,
                introVidEnded,
                setIntroVidEnded,
                showIntro,
                setShowIntro,
                skipIntro,
                setSkipIntro,
                pageId,
                setPageId, getMediaPath
            }}
        >
            {children}
        </UmbracoContext.Provider>
    );
};
